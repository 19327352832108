module.exports = {
  path: "/en",
  name: "index_en",
  component: () => import('../../views/zh/index.vue'),
  meta: {
    title:
      "佳智惠科技有限公司—智能安防，UWB定位系统、智能安全帽、4G通讯模组及物联网平台供应商",
    content: {
      keywords:
        "智能安全帽,智能安全帽生产厂家,智慧安全帽,智能烟感,智慧烟感,智能烟感厂家,独立烟感报警器,无线烟感,UWB高精度定位系统,户外电源,佳智惠,深圳佳智惠科技有限公司,佳智惠烟感,bbjt",
      description:
        "深圳佳智惠科技有限公司生产的佳智惠安全帽、智能烟感、UWB定位系统、户外电源等产品功能完备、可靠性高，续航强劲，先后通过IOS9001质量管理体系认证、中国3C认证、欧盟CE认证等18项多个国家和地区的专业认证。",
    },
  },
  children: [
    {
      path: '/',
      redirect: 'product',
    },
    {
      path: "product",
      name: "home_en",
      component: () => import("../../views/product.vue"),
      meta: {
        title:
          "佳智惠科技有限公司—智能安防，UWB定位系统、智能安全帽、4G通讯模组及物联网平台供应商",
        content: {
          keywords:
            "智能安全帽,智能安全帽生产厂家,智慧安全帽,智能烟感,智慧烟感,智能烟感厂家,独立烟感报警器,无线烟感,UWB高精度定位系统,户外电源,佳智惠,深圳佳智惠科技有限公司,佳智惠烟感,bbjt",
          description:
            "深圳佳智惠科技有限公司生产的佳智惠安全帽、智能烟感、UWB定位系统、户外电源等产品功能完备、可靠性高，续航强劲，先后通过IOS9001质量管理体系认证、中国3C认证、欧盟CE认证等18项多个国家和地区的专业认证。",
        },
      },
      children: [
        {
          path: '/',
          redirect: 'index'
        },
        {
          path: 'index',
          name: 'index_en',
          component: () => import('../../views/products/index.vue'),
          meta: {
            title: '产品中心首页'
          }
        },
        {
          path: 'watch',
          name: 'watch_en',
          component: () => import('../../views/products/watch.vue'),
          meta: {
            title: '定位手表'
          }
        },
        {
          path: 'platForm',
          name: 'platForm_en',
          component: () => import('../../views/products/platForm.vue'),
          meta: {
            title: '智能平台'
          }
        }, {
          path: 'safetyHat',
          name: 'safetyHat_en',
          component: () => import('../../views/products/safetyHat.vue'),
          meta: {
            title: '智能安全帽'
          }
        }, {
          path: 'smartElectricity',
          name: 'smartElectricity_en',
          component: () => import('../../views/products/smartElectricity.vue'),
          meta: {
            title: '智慧用电'
          }
        }, {
          path: 'smokeDetector',
          name: 'smokeDetector_en',
          component: () => import('../../views/products/smokeDetector.vue'),
          meta: {
            title: '烟雾报警器'
          }
        }, {
          path: 'touringCar',
          name: 'touringCar_en',
          component: () => import('../../views/products/touringCar.vue'),
          meta: {
            title: '智能房车'
          }
        }, {
          path: 'waterMeter',
          name: 'waterMeter_en',
          component: () => import('../../views/products/waterMeter.vue'),
          meta: {
            title: '智能水表'
          }
        },
        {
          path: 'fireEngine',
          name: 'fireEngine_en',
          component: () => import('../../views/products/fireEngine.vue'),
          meta: {
            title: '消防车辆'
          }
        },
      ]
    },
    {
      path: "agent",
      name: "agent_en",
      component: () => import("../../views/about/agent.vue"),
      meta: {
        title:
          "佳智惠科技有限公司—智能安防，UWB定位系统、智能安全帽、4G通讯模组及物联网平台供应商",
        content: {
          keywords:
            "佳智惠简介,佳智惠企业简介,佳智惠企业文化,佳智惠荣誉资质,佳智惠合作伙伴,佳智惠联系方式,佳智惠电话,佳智惠联系电话,深圳佳智惠科技有限公司,bbjt",
          description:
            "深圳佳智惠科技有限公司成立于2019年，总部位于深圳市宝安区，是国内安防产品制造业为数不多的集自主研发、生产体系、解决方案于一体的国家高新技术企业，研发团队由国内外知名专家学者和业内资深工程师组成，累计获得各项技术专利68项，产品体系涵盖智慧城市、智慧工厂、智慧消防、智慧工地、智慧社区、智慧校园等多个应用场景。",
        },
      },
    },
    {
      path: "about",
      name: "about_en",
      component: () => import("../../views/about/about.vue"),
      meta: {
        title: "佳智惠——关于佳智惠",
        content: {
          keywords:
            "佳智惠简介,佳智惠企业简介,佳智惠企业文化,佳智惠荣誉资质,佳智惠合作伙伴,佳智惠联系方式,佳智惠电话,佳智惠联系电话,深圳佳智惠科技有限公司,bbjt",
          description:
            "深圳佳智惠科技有限公司成立于2019年，总部位于深圳市宝安区，是国内安防产品制造业为数不多的集自主研发、生产体系、解决方案于一体的国家高新技术企业，研发团队由国内外知名专家学者和业内资深工程师组成，累计获得各项技术专利68项，产品体系涵盖智慧城市、智慧工厂、智慧消防、智慧工地、智慧社区、智慧校园等多个应用场景。",
        },
      },
    },
    {
      path: "solution",
      name: "solution_en",
      component: () => import("../../views/solution/solution.vue"),
      meta: {
        title: "佳智惠——智能一体化解决方案",
        content: {
          keywords:
            "智慧城市,智慧工厂,智慧消防,智慧工地,智慧社区,智慧校园,佳智惠,佳智惠解决方案,深圳佳智惠科技有限公司,bbjt",
          description:
            "佳智惠精心打造智慧城市、智慧工厂、智慧消防、智慧工地、智慧社区、智慧校园等应用场景解决方案，为我国各行业安全生产助力赋能。并可根据行业特别需求提供相应的深度定制解决方案，坚持以技术研发为中心，以自主创新打造品牌核心竞争力。",
        },
      },
    },
    {
      path: "solutionDetail",
      name: "solutionDetail_en",
      component: () => import("../../views/solution/solutionDetail.vue"),
    },
    {
      path: "case",
      name: "case_en",
      component: () => import("../../views/case.vue"),
      meta: {
        title: "佳智惠——项目案例",
        content: {
          keywords:
            "佳智惠项目案例,佳智惠工程案例,佳智惠解决方案,佳智惠,深圳佳智惠科技有限公司,bbjt",
          description:
            "佳智惠斥巨资引进国内外顶级的现代化生产线和检测设备，并先后通过IOS9001质量管理体系认证、中国3C认证、欧盟CE认证等18项多个国家和地区的专业认证，卓越的品质、过硬的质量和完善的售后，让佳智惠赢得了中交集团、中铁集团、中国建筑、中国安能、中国风电等合作伙伴的高度认可和一致青睐，营销网络覆盖亚非欧多个国家和地区。",
        },
      },
    },
    {
      path: "dynamic",
      name: "dynamic_en",
      component: () => import("../../views/dynamic.vue"),
      meta: {
        title: "佳智惠——佳智惠资讯",
        content: {
          keywords:
            "佳智惠动态、佳智惠资讯、佳智惠最新动态、佳智惠新闻、佳智惠最新政策、深圳佳智惠科技有限公司,bbjt",
          description:
            "佳智惠将始终如一地坚持自主研发路线、贯彻自主创新理念，秉承创造社会价值、实现自我增值的经营理念，以满足客户需求为己任、以开拓、创新、创造为宗旨，以诚信、专业、拼搏、感恩的企业价值观成就行业标杆，为我国各行业安全生产和人民幸福生活保驾护航。",
        },
      },
    },
    {
      path: "dynamicDetail", //文章详情
      name: "dynamicDetail_en",
      component: () => import("../../views/dynamicDetail.vue"),
    },
    {
      path: "video",
      name: "video_en",
      component: () => import("../../views/video/video.vue"),
      meta: {
        title: "佳智惠——在线视频",
        content: {
          keywords:
            "佳智惠在线视频,佳智惠视频,智能安全帽视频,佳智惠直播,佳智惠在线直播,佳智惠视频攻略,佳智惠教程,bbjt",
          description:
            "佳智惠在线视频栏目发布由佳智惠官方制作或由用户自行上传的各类精彩视频，佳智惠也将不定期进行直播，精彩呈现，尽在佳智惠视频。",
        },
      },
    },
    {
      path: "serve",
      name: "serve_en",
      component: () => import("../../views/serve/serve.vue"),
      meta: {
        title: "佳智惠——服务支持",
        content: {
          keywords:
            "佳智惠资料下载,佳智惠产品查询,佳智惠产品真伪查询,佳智惠软件下载,深圳佳智惠科技有限公司,bbjt",
          description:
            "佳智惠产品具有超小尺寸、超低功耗、易于集成、长期使用性和超高稳定性等特点。在物联网、智能硬件、saas智能大数据、人工智能、融合通信、软件技术等",
        },
      },
    },
    {
      path: "homes", //我的
      name: "homes_en",
      component: () => import("../../views/forum/homes.vue"),
      meta: {
        title: "佳智惠行业交流论坛",
        content: {
          keywords:
            "佳智惠论坛,智能安全帽行业论坛,智能安全帽论坛,佳智惠论坛,佳智惠在线留言,佳智惠意见建议,佳智惠投诉建议,佳智惠投诉,bbjt",
          description:
            "佳智惠行业交流论坛旨在为业内提供一个公平公正公开的行业交流平台，用户在交流过程中请务必遵纪守法、文明礼貌，对佳智惠发展相关的意见建议、投诉均可在此留言。",
        },
      },
    },
    {
      path: "my", //我的
      name: "my_en",
      component: () => import("../../views/forum/my.vue"),
    },
    {
      path: "article", //发表文章
      name: "article_en",
      component: () => import("../../views/forum/article.vue"),
    },
    {
      path: "srticleDetail", //文章详情
      name: "srticleDetail_en",
      component: () => import("../../views/forum/srticleDetail.vue"),
    },
    {
      path: "goodsDetail", //商品详情
      name: "goodsDetail_en",
      component: () => import("../../views/goodsDetail.vue"),
    },
    {
      path: "casesDetail", //案例详情
      name: "casesDetail_en",
      component: () => import("../../views/casesDetail.vue"),
    },
    {
      path: "noLogin", //物流信息
      name: "noLogin_en",
      component: () => import("../../views/noLogin.vue"),
    },
  ]
}
