// 英文

module.exports = {
  banner: {
    ljlj: 'Learn more',
  },
  header: {
    bbzn: 'BaBa intelligence',
    hyn: 'Welcome!',
    wddd: 'orders',
    gwc: 'Cart',
    ptdl: 'platform login',
    qdl: 'Sign in',
    tcdl: 'Exit login ',
    dhl: 'Navigation bar'
  },
  nav: {
    bbwh: "Put the culture",
    home: "Homepage",
    flag: "{WHO} name is {What}",
    zhcs: 'Smart city',
    zhgc: 'Smart factory',
    zhxf: 'Wisdom fire',
    zhgd: 'Wisdom site',
    zhsq: 'Intelligence community',
    zhxy: 'Wisdom campus',
    bbdt: 'Put the dynamic',
    zwcx: "The authenticity of the query",
    zlxz: "Download information",
    rjxz: "Software download",
    gysly: "Vendor message",
    khly: "Customer comment",
    bbjj: "Put introduction",
    ryzz: "Honor",
    lxwm: "Contact us",
    hydt: 'Industry dynamics ',
    bbbk: 'Encyclopedia',
    cpzx: 'PRODUCTS',
    jjfa: 'SOLUTIONS',
    xmal: 'PROJECTS',
    bbzx: 'INFORMATIONS',
    fwzc: 'SERVICE',
    zsdl: 'INVESTORS',
    gybb: 'ABOUT US',
    zxsp: 'VIDEO',
    hylt: 'BBS',
    appdown:'APP DOWN',
    yhxy:'User Agreement',
    ystk:'Privacy Clause',
    fhtkzc:'Shipping Refund Policy',
  },
  product: {
    ljgm: 'Detail',
    cpzxsy: 'Product Center',
    znaqm: 'Smart helmet',
    ywbjq: 'Smoke alarm',
    dwsb: 'Positioning Watch',
    zhyd: 'Wisdom electricity',
    znsb: 'Smart meter',
    znpt: 'Smart platform',
    yyfc: 'Off-road dumper',
    xfcl: 'Fire fighting vehicles',
    ss: 'Search'
  },
  footer: {

  },
  solution: {
    ckgd: 'View more'
  },
  common: {
    sy: 'Homepage',
  },
  breadcrumb: {
    xwdt: 'News dynamics',
    bbdt: 'Put the dynamic',
    hydt: 'Industry dynamics',
    bbbk: 'Put Wikipedia',
    ljxq: 'Understand details'
  },
  serve: {
    fwzc: 'Service support',
    zlxz: 'Download information',
    rjxz: 'Software download',
    gysly: 'Vendor message',
    yhxy:'User Agreement',
    ystk:'Privacy Clause',
    fhtkzc:'Shipping Refund Policy',
    zwcx: 'The authenticity of the query',
    zwcx_placeholder: 'Please enter the product IMei',
    cpxx: "Product Information",
    pcy: "Quality Assurance / Long Warranty / Worry-Free Operation",
    cpxh: "product model",
    ys: "color",
    sbh: "Device Number",
    ccrq: "Date of manufacture",
    sccj: "manufacturer",
    zjy: 'quality inspector',
    xsryxm: "Salesperson Name",
    dlsmc: "Agent Name",
    gwrk: "Official Website Portal"
  },
  goodsDetail: {
    price: 'Price ',
    number: 'quantity ',
    addgoodscat: 'Add to the shopping cart ',
    buynow: 'Buy Now!'
  },
  safetyHat: {
    cpzxsy: 'Product Center Home',
    ss: 'Search',
    buynow: 'Detail'
  },
  fireEngine: {
    cpzxsy: 'Product Center Home',
    ss: 'Search',
    buynow: 'Detail'
  },
  platForm: {
    cpzxsy: 'Product Center Home',
    ss: 'Search',
    buynow: 'Detail'
  },
  smartElectricity: {
    cpzxsy: 'Product Center Home',
    ss: 'Search',
    buynow: 'Detail'
  },
  smokeDetector: {
    cpzxsy: 'Product Center Home',
    ss: 'Search',
    buynow: 'Detail'
  },
  touringCar: {
    cpzxsy: 'Product Center Home',
    ss: 'Search',
    buynow: 'Detail'
  },
  watch: {
    cpzxsy: 'Product Center Home',
    ss: 'Search',
    buynow: 'Detail'
  },
  waterMeter: {
    cpzxsy: 'Product Center Home',
    ss: 'Search',
    buynow: 'Detail'
  },
  dynamic: {
    home: "Homepage",
    xwdt: 'News dynamics'
  },
  dynamicDetail: {
    zz: "author"
  },
  dynamicBox: {
    ljxq: 'Understand details'
  },
  supplier: {
    qymc: 'Enterprise name',
    qcrqymc: 'Please enter the company name',
    xm: "Name",
    qcrxm:'Please enter a name',
    qsrxm: "Please enter a name",
    yx: 'Email',
    qsuyx: 'Please enter email',
    dh: 'Phone',
    qcrsjh:"Please enter a phone number",
    qsrdh: "Please enter a phone number",
    nr: 'Content',
    qcrnr:'Please enter content',
    qsrnr: "Please enter content",
    tj: "Submit",
    cz: 'Reset',
    qtxzqddhhm: 'Please fill in the correct phone number',
    qtxzqdyx: 'Please fill in the correct email address',
    qsrqymc: 'Please enter the company name',
    qsrsjh: "Please enter your phone number",
  },
  agentForm: {
    xm: "Name",
    qsrxm: "Please enter a name",
    yx: 'Email',
    qsuyx: 'Please enter email',
    dh: 'Phone',
    qsrdh: "Please enter a phone number",
    nr: 'Content',
    qsrnr: "Please enter content",
    tj: "Submit",
    cz: 'Reset',
    qtxzqddhhm: 'Please fill in the correct phone number',
    qtxzqdyx: 'Please fill in the correct email address',
    qsrqymc: 'Please enter the company name',
    qsrsjh: "Please enter your phone number",
  },
  about: {
    dh: 'telephone',
    yx: 'mail',
    zbgsdz: 'Headquarters Company Address',
    zbgsmc:'Headquarters Company Name',
    fbgsmc:'Name of distribution company',
    fbgsdz:'Distribution company address'
  },
  footerContent: {
    jzhxz: 'Jia Zhihui download',
    qgzxfwrx: "National Hotline",
    gsdzvalue: "Building 11, 4th Floor, Tangtou Nangang Third Industrial Park, Tangtou Community, Shiyan Street, Bao'an District, Shenzhen",
    gsdz: "Company address",
    gzwxgzh: "Follow WeChat public account",
    gzwb: "Follow Weibo",
    wxgzh: "WeChat public account",
    APPxz: "APP Download",
    bq: "Shenzhen will put the intelligent technology Co., Ltd. all rights reserved",
    xz: "Download the app",
    znsfcp: "Smart Justice Products",
    znxfcp: 'intelligent fire protection products',
    znafcp: 'smart security products',
    znshcp: 'smart life products',
    znpj: 'smart accessories',
    znglpt: 'Intelligent Management Platform',
    zhcs: 'smart city',
    zhgc: 'smart factory',
    zhxf: 'smart fire protection',
    zhgd: 'smart construction site',
    zhsq: 'smart community',
    zhxy: 'smart campus',
    jjfa: 'solution',
    cpzxsy: 'Home of Products',
    bbzx: 'Put the information',
    bbdt: 'Put the dynamic',
    hydt: 'Industry News',
    bbbk: 'Put the encyclopedia',
    fwzc: 'service support',
    bbjj: "Put the introduction",
    ryzz: "honor",
    gybb: 'About the handle',
    bbwh: "Put the culture",
    lxwm: "Contact Us",
    hzhb: "partner",
    zlxz: 'data download',
    rjxz: 'software download',
    gysly: 'Supplier message',
    zwcx: 'authenticity query',
    khly: "Customer message",
    yhxy:'User Agreement',
    ystk:'Privacy Clause',
    fhtkzc:'Shipping Refund Policy',
  },
  login: {
    bbzn: "Put the intelligence",
    sjh: 'mobile phone number',
    mm: 'password',
    zczh: "register an account",
    dl: 'login',
    qdl: "Please log in",
    zc: 'Register',
    zh: "account number",
    qsrzh: 'Please enter account number',
    qsrmm: 'Please enter password',
    qrmm: 'confirm password',
    qzcsrmm: "Please enter the password again",
    qsrsjh: "Please enter your mobile phone number",
    yzm: "captcha",
    qsryzm: "Please enter the verification code",
    cd: "The length must be between 6-12 bits",
    qtxzqdsjhm: "Please fill in the correct mobile phone number",
    MMTJ: "Password must contain numbers and letters, 6-20 digits",
    mmbyz: 'password inconsistency',
    hqyzm: "Get Captcha",
    get: ' after refetching'
  },
  logisticsInfo: {
    wlxq: 'logistics details',
    ydhm: 'Waybill Number',
    wlgs: 'Logistics Company',
    kfdh: 'customer service number',
    wlgsgfwz: 'official website of logistics companies',
    zwwlxx: 'No logistics information'
  },
  MyAddress: {
    shdz: 'delivery address',
    tjxdz: "Add New Address",
    mr: "default",
    xg: "modify",
    sc: 'delete',
    shr: 'Consignee',
    shdq: 'Receiving Area',
    xxdz: 'full address',
    sjhm: 'mobile number',
    scdzcg: 'address deleted successfully',
    xz: 'select',
    dzbm: 'address alias',
    mrdz: 'default address',
    qsrsjrxm: 'Please enter the recipient\'s name',
    qsrdz: 'Please enter address',
    qsrxxdz: 'Please enter full address',
    sjhbnwk: 'mobile phone number cannot be empty',
    sjhgscc: "The mobile phone number format is wrong",
    xgdzcg: 'address changed successfully',
    xinz: 'new',
    tjdzcg: 'address added successfully'
  },
  MyOrder: {
    dd: 'order',
    qbdd: 'All Orders',
    dfk: "pending",
    dfh: 'To be shipped',
    dsh: 'To be received',
    ywc: 'completed',
    my: 'per page',
    tiao: 'article'

  },
  orderDetail: {
    ddxq: 'Order Details',
    ddh: 'order number',
    cjsj: 'creation time',
    shrx: 'Consignee Information',
    shr: 'Consignee',
    sjhm: 'mobile number',
    shdz: 'delivery address',
    cptp: 'Product Images',
    cpmc: 'product name',
    dj: 'unit price',
    sl: 'quantity',
  },
  pay: {
    shrxx: 'Consignee Information',
    glshrdz: "managing consignee addresses",
    xg: "modify",
    sc: 'delete',
    tjxdz: 'add new address',
    sqdz: 'collapse address',
    gddz: 'more addresses',
    spxx: "product information",
    fhgwc: 'Back to cart',
    yh: 'in stock',
    wu: 'out of stock',
    ddbz: 'Order Notes',
    ts: 'Tip: Please do not fill in information about payment, receipt,',
    tjdd: 'submit order',
    psz: 'ship to',
    shdz: 'delivery address',
    shr: 'Consignee',
    zffs:'Payment method',
    qxzndzffs:'Please select your payment method',
    jnzf:'Domestic payment',
    jwzf:'Overseas payment',
    qx:'cancellation',
    qr:'confirm'
  },
  payment: {
    ddtjcg: 'Order submitted successfully, please pay as soon as possible!',
    qnjkwczf: 'Please complete the payment as soon as possible, otherwise the order will be automatically canceled',
    dycsqx: 'order has been cancelled after timeout',
    yfje: 'Amount due',
    yuan: 'yuan',
    qsywxsmzf: 'Please use WeChat scan code to pay',
    zfcghzdtz: 'automatic jump after successful payment, if you want to jump, please click the button to jump manually',
    cxzf: 'repay',
    zfb: 'Alipay',
    wx: 'WeChat',
    zfcg: 'Payment Success'
  },
  shopping: {
    tp: 'image',
    spmc: 'Trade Name',
    dj: 'unit price',
    xj: 'subtotal',
    sl: 'quantity',
    cz: "operation",
    jxll: 'Continue browsing',
    gong: 'Gong',
    jcp: 'piece product',
    hj: 'total',
    qjs: 'Go to settlement',
    cczjycgsp: 'This action will remove the product, do you want to continue?',
    ts: 'hint',
    qr: 'confirm',
    qx: 'cancel',
    yqxyc: 'unremoved',
    ycspcg: 'Removal of item successful',
    xgslcg: 'number of modifications successful',
    qxznyjsdsp: 'Please select the commodity you want to check',

  },
  menu: {
    gwc: 'shopping cart',
    ddzx: 'Order Center',
    wddd: 'My Orders',
    shdz: 'harvest address',

  },
  noLogin: {
    ljzf: 'payment',
    qxss: 'Brand New',
    sl: 'quantity',
    yfje: 'Amount due',
    gmxx: 'Purchase Information',
    shxx: 'receipt information',
    shr: 'Consignee',
    qsrndzwmhywm: 'Please enter your Chinese name or English name',
    shdz: 'delivery address',
    qsrndshdz: 'Please enter your harvest address',
    lxdh: 'contact number',
    qsrndlxfs: 'Please enter your contact details',
    yzm: 'captcha',
    qsryzm: 'Please enter the verification code',
    qsrzqdsjh: 'Please enter the correct mobile phone number',
    zfb: 'Alipay',
    wx: 'WeChat',
    gsjhybzy: 'This phone number is already taken',
    gsjhmyzcqdl: 'This mobile number is registered, please log in'
  },
  home: {
    tjcp: 'Recommended Products',
    ckgd: 'see more',
    jjfa: 'solution',
    bbznglpt: 'put the intelligent management platform',
    xmal: 'project case',
    zlhzhb: 'strategic partner',
    yljh: 'links',
    bbscrk: 'Put the entrance to the mall',
    bbptdl: 'log the platform in',
    gysrk: 'supplier entry',
    hzhbrk: 'partner portal',
    zsjmrk: 'investment entry entry'
  },
  orderBox: {
    ddh: 'order number',
    cjsj: 'creation time',
    ddxq: 'Order Details',
    dzf: 'To be paid',
    sdgb: 'manual shutdown',
    qxdd: 'cancel order',
    wzf: 'unpaid',
    chgb: 'timeout off',
    zfsb: 'payment failed',
    qzf: 'Go pay',
    ckcg: 'outbound successful',
    ywc: 'completed',
    kcwl: 'View Logistics',
    qrsh: 'confirm receipt',
    qxzqxddyy: 'Please select a reason for cancellation',
    qx: 'cancel',
    qr: 'confirm',
    qrgb: 'confirm close',
    qxddcg: 'Cancelled order successful',
    nqryshm: 'Are you sure you want to take the goods?',
    ts: 'hint',
    yqxsh: 'Cancelled',
    wldt:'Logistics dynamics',
    shfw:'after-sale service',
    sqsh:'Apply for after-sales service',
    shfw:'after-sale service',
    qxzshfw:'Please select the after-sales type',
    gzjj:'Troubleshooting',
    hh:'exchange goods',
    thtk:"Returns and refunds",
    tkbth:"Refund not return"
  }

}
